import React, {useEffect, useRef} from 'react';
import {IPosition} from 'providers/LocationProvider';
import {VideoPlayer} from "components/VideoPlayer";
import {Api} from "../api/client";
import {
  PartiesNewResponse,
  StoriesLocationCollectOneRequest,
  StoriesNewOwnTourResponse,
  StoriesNewRequest,
  StoriesRecommendResponse,
  StoriesStartRequest,
  TripsNewResponse,
  UsersNewResponse,
  VehiclesNewResponse
} from "../api/types";
import { useWakeLock } from 'react-screen-wake-lock';
import Note from './Note';
import Term from './Term';
import HowToUse from './HowToUse';

type Status =
  | null
  | 'init' // 初期
  | 'users' // ユーザー作成完了
  | 'parties' // parties作成完了
  | 'vehicle' // vehicle作成完了
  | 'trip' // trip作成完了
  | 'story_new' // story_new作成完了
  | 'story_start' // story_start作成完了
  | 'command' // commandでrecommendが見つかった
  | 'ready' // 音声準備完了

interface Props {
  position: IPosition;
}

const MainComponent: React.FC<Props> = ({ position }) => {
  const [status, setStatus] = React.useState<Status>(null);
  const userResponseRef = React.useRef<UsersNewResponse | null>(null);
  const partyResponseRef = React.useRef<PartiesNewResponse | null>(null);
  const vehicleResponseRef = React.useRef<VehiclesNewResponse | null>(null);
  const tripResponseRef = React.useRef<TripsNewResponse | null>(null);
  const storyResponseRef = React.useRef<StoriesNewOwnTourResponse | null>(null);
  const recommendIdRef = React.useRef<string | null>(null);
  const [description, setDescription] = React.useState('');
  const [mp3Url, setMp3Url] = React.useState('');
　 //const dummyAudio = new Audio("https://wh-rhythm-dev-s3.s3.ap-northeast-1.amazonaws.com/ok.mp3");
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [isError, setIsError] = React.useState<boolean>(false);
  const audioContext = useRef<AudioContext | null>(null);

  const { isSupported, released, request, release } = useWakeLock({
    onRequest: () => console.log('Screen Wake Lock: requested!'),
    onError: () => console.log('An error happened 💥'),
    onRelease: () => console.log('Screen Wake Lock: released!'),
  });
  const [modalDisplay, setModalDisplay] = React.useState<string>('none');
  const [terms, setTerms] = React.useState<string>('');
  const [howToUse, setHowToUse] = React.useState<string>('');
  const [note, setNote] = React.useState<string>('');

  useEffect(() => {
    if (status !== null) return;
    setTimeout(() => {
      setModalDisplay('block');
    }, 1000);
  }, []);

  // 初期処理
  // ユーザー作成してBearer Tokenもらう
  useEffect(() => {
    if (status !== 'init') return;

    // ユーザー作成
    Api.usersNew().then(response => {
      if (response) {
        userResponseRef.current = response.data;
        setStatus('users');
      }
    }).catch(error =>  {
      setErrorMessage(error.message);
      setIsError(true);
    })
  }, [status]);

  // parties作成してparties_idもらう
  useEffect(() => {
    if (status !== 'users') return;
    if (!userResponseRef.current) return;
    // parties作成
    Api.partiesNew(userResponseRef.current.entry.bearer_token).then(response => {
      if (response) {
        partyResponseRef.current = response.data;
        setStatus('parties');
      }
    }).catch(error =>  {
      setErrorMessage(error.message);
      setIsError(true);
    })
  }, [status]);

  // vehicle作成してvehicle_idもらう
  useEffect(() => {
    if (status !== 'parties') return;
    if (!userResponseRef.current) return;
    // vehicle作成
    Api.vehiclesNew(userResponseRef.current.entry.bearer_token).then(response => {
      if (response) {
        vehicleResponseRef.current = response.data;
        setStatus('vehicle');
      }
    }).catch(error =>  {
      setErrorMessage(error.message);
      setIsError(true);
    })
  }, [status]);

  // trip作成して、trip_id貰う
  useEffect(() => {
    if (status !== 'vehicle') return;
    if (!userResponseRef.current) return;
    // trip作成
    Api.tripsNew(userResponseRef.current.entry.bearer_token).then(response => {
      if (response) {
        tripResponseRef.current = response.data;
        setStatus('trip');
      }
    }).catch(error =>  {
      setErrorMessage(error.message);
      setIsError(true);
    })
  }, [status]);

  // story作成して、story_id貰う
  useEffect(() => {
    if (!userResponseRef.current) return;
    if (!partyResponseRef.current) return;
    if (!tripResponseRef.current) return;
    if (!vehicleResponseRef.current) return;

    if (status !== 'trip') return;
    const request: StoriesNewRequest = {
      party_id : partyResponseRef.current.entry.id,
      trip_id : tripResponseRef.current.entry.id,
      vehicle_id : vehicleResponseRef.current?.entry.id,
      app_tag : 'Nexco'
    }

    // story作成
    Api.storiesNewOwnTour(userResponseRef.current.entry.bearer_token, request).then(response => {
      if (response) {
        storyResponseRef.current = response.data;
        setStatus('story_new');
      }
    }).catch(function (error)  {
      setErrorMessage(error.message);
      setIsError(true);
    })
  }, [status]);

  //初期の位置情報通知する
  useEffect(() => {
    if (!userResponseRef.current) return;
    if (!storyResponseRef.current) return;
    if (position.longitude == null || position.latitude == null) return;
    if (status !== 'story_new') return;

    const request: StoriesStartRequest = {
      location: {
        longitude: position.longitude,
        latitude: position.latitude
      }
    }
    // ストーリー開始作成
    Api.storiesStart(userResponseRef.current.entry.bearer_token, storyResponseRef.current.story.id, request).then(response => {
      if (response) {
        setStatus('story_start');
      }
    }).catch(error =>  {
      setErrorMessage(error.message);
      setIsError(true);
    })
  }, [status, position.latitude, position.longitude]);

  useEffect(() => {
    if (status !== 'story_start') return;
    if (!userResponseRef.current) return;
    if (!storyResponseRef.current) return;
    if (position.longitude == null || position.latitude == null) return;

    const request: StoriesLocationCollectOneRequest = {
      longitude: position.longitude,
      latitude: position.latitude
    };

    Api.storiesLocationCollectOne(
      userResponseRef.current.entry.bearer_token,
      storyResponseRef.current.story.id,
      request
    ).catch(error => {
      setErrorMessage(error.message);
      setIsError(true);
    }).then(() => {
      // recommendあるか見る
      return Api.storiesCommands(
        userResponseRef.current!.entry.bearer_token,
        storyResponseRef.current!.story.id
      );
    }).then(response => {
      if (response && response.data) {
        if ('entries' in response.data) {
          const entries = response.data.entries;
          for (let entry of entries) {
            if (entry.type === 'recommendation' && 'payload' in entry && 'recommendation_id' in entry.payload) {
              recommendIdRef.current = entry.payload.recommendation_id;
              setStatus('command');
              break;
            }
          }
        }
      }
    }).catch(error => {
      setErrorMessage(error.message);
      setIsError(true);
    });
  }, [status, position.longitude, position.latitude]);

  //コンテンツが見つかったので、詳細情報を取得する
  useEffect(()=> {
    if (!userResponseRef.current) return;
    if (!storyResponseRef.current) return;
    if (!recommendIdRef.current) return;
    if (status !== 'command') return;

    Api.storiesRecommendations(userResponseRef.current.entry.bearer_token, storyResponseRef.current.story.id, recommendIdRef.current).then(response => {
      if (response) {
        const recommendation : StoriesRecommendResponse = response.data;
        setDescription(recommendation.entry.content.description);
        setMp3Url(recommendation.entry.content.long_sound_mp3_url);
        setStatus('ready')
        if (audioContext.current != null) {
          const audioContextValue = audioContext.current;
          fetch(recommendation.entry.content.long_sound_mp3_url, {
            method: 'GET',
            mode: 'cors', // no-cors, *cors, same-origin
          })
            .then(response => response.arrayBuffer())
            .then(arrayBuffer => audioContextValue.decodeAudioData(arrayBuffer))
            .then(audioBuffer => {
              const audioSource = audioContextValue.createBufferSource();
              audioSource.buffer = audioBuffer;
              audioSource.connect(audioContextValue.destination);
              audioSource.loop = false;
              audioSource.onended = endSound;
              audioSource.start();
            });
        }
      }
    }).catch(error =>  {
      setErrorMessage(error.message);
      setIsError(true);
    })
  }, [status])

  // エラー表示から5秒経過したら元画面表示に戻す
  useEffect(() => {
    if (!isError) return;
    setTimeout(() =>{
      setIsError(false);
    }, 5000)
  }, [isError])

  const endSound = () => {
    // 再生終わったのでtour登録からやり直す
    setStatus('trip');
    setDescription('');
    setMp3Url('');
  }

  const handleTerm = () => {
    setTerms(terms === ''? 'active' : '');
  }

  const handleHowToUse = () => {
    setHowToUse(howToUse === ''? 'active' : '');
  }

  const handleNote = () => {
    setNote(note === ''? 'active' : '');
  }

  if (position.error) {
    setTimeout(() => {
      setIsError(true);
      // @ts-ignore
      setErrorMessage(position.error)
    }, 1000);
  }

  return (
    <div className="rhythm">
      <div className="notification-bar">
        <img src="./assets/svg/character_small.svg" alt=""/>
        運転中の操作・画面の注視は　しないでください
        <img src="./assets/svg/character_small.svg" alt=""/>
      </div>
      <div className="page">
        <div className="header">
          <button onClick={handleHowToUse}>
            <img src="./assets/svg/button.svg" alt=""/>
            わく旅の使い方
          </button>
        </div>
        <div className="heading">
          <img src="./assets/svg/logo.svg" alt=""/>
        </div>
        <div className="content">
          <div className={`active-block ${status === 'ready' && isError === false ? 'active' : ''}`}>
            <div className="title">
              <div>{description}</div>
            </div>
            {status === 'ready' && (
              <VideoPlayer src={"./assets/img/active.mp4"} loop={true} mute={true}/>
            )}
          </div>
          <div className={`standby-block ${status !== 'ready' && isError === false ? 'active' : ''}`}>
            <p className="standby-text">位置情報を更新しています...</p>
            <VideoPlayer src={"./assets/img/standby.mp4"} loop={true} mute={true}/>
            <p className="standby-text" style={{fontSize:"14px", lineHeight:"18px", color:"black"}}>♪再生中♪</p>
            <p className="standby-text" style={{fontSize:"14px", lineHeight:"18px", color:"black"}}>しずぎん&めいぎん presents 地域じまん特集</p>
            <p className="standby-text" style={{fontSize:"14px", lineHeight:"18px", color:"black"}}>（2024年9月21日～2025年1月31日） </p>
            <p className="standby-text" style={{fontSize:"14px", lineHeight:"18px", color:"black"}}>※ご利用中は常にこの画面を表示しておいてください</p>
          </div>
          <div className={`error-block ${isError ? 'active' : ''}`}>
            <div>
              <img src="./assets/svg/warn.svg"/>
              画面を更新してください
            </div>
            <div>
              <img src="./assets/svg/warn.svg"/>
              サーバーエラーが発生しました ({errorMessage})
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <p id="termsOfServiceOpenButton" onClick={handleTerm}>利用規約</p>
        {/*<p id="noteOpenButton" onClick={handleNote}>注意事項</p>*/}
      </div>

      <Note note={note} handleClick={handleNote}/>
      <Term terms={terms} handleClick={handleTerm}/>
      <HowToUse howToUse={howToUse} handleClick={handleHowToUse}/>

      <div className="overlay" style={{display: modalDisplay}}>
        <div className="modal-dialog">
          <div className="head">
            <div className="title">
              <div>わく旅に参加しますか？</div>
              <p>運転中の操作はご遠慮ください</p>
            </div>
            <div className="content" onClick={handleNote}>利用規約をお読みください。</div>
          </div>
          <div className="button">
            <button data-type="secondary-black" onClick={() => {
              window.location.href = 'https://wkwkhw-links.aws.maasified.com';
            }}>いいえ
            </button>
            <button data-type="prime-red" onClick={() => {
              request();
              audioContext.current = new AudioContext();
              if (audioContext.current != null) {
                const audioContextValue = audioContext.current;
                fetch('https://wh-rhythm-dev-s3.s3.ap-northeast-1.amazonaws.com/ok.mp3', {
                  method: 'GET',
                  mode: 'cors', // no-cors, *cors, same-origin
                })
                  .then(response => response.arrayBuffer())
                  .then(arrayBuffer => audioContextValue.decodeAudioData(arrayBuffer))
                  .then(audioBuffer => {
                    const audioSource = audioContextValue.createBufferSource();
                    audioSource.buffer = audioBuffer;
                    audioSource.connect(audioContextValue.destination);
                    audioSource.loop = false;
                    audioSource.start();
                  });
              }
              setModalDisplay('none');
              setStatus('init');
            }}>はい
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};


export default MainComponent;
