import React from "react";

type Props = {
  src: string;
  loop?: boolean;
  mute?: boolean;
};


// @ts-ignore
export const VideoPlayer = (props: Props) => {
  return (
    <video src={props.src} autoPlay loop={props.loop} muted={props.mute} playsInline></video>
  );
}
