import React, {useContext} from 'react';
import LocationProvider, {LocationContext } from "providers/LocationProvider";
import MainComponent from "components/MainComponent";

const App: React.FC = () => {
  return (
    <LocationProvider>
      <AppComponent />
    </LocationProvider>
  );
};

const AppComponent: React.FC = () => {
  const position = useContext(LocationContext);

  if (!position) {
    return <div></div>;
  }

  return <MainComponent position={position} />;
};

export default App;
