export const BASE_URL = "https://v2.aisin-rhythm.com";
export const ENDPOINTS = {
  usersNew: "/users/new",
  partiesNew: "parties/new",
  vehiclesNew: "vehicles/new",
  tripsNew: "trips/new",
  storiesNewOwnTour : "stories/new/own-tour",
  storiesStart : "stories/{story_id}/start",
  storiesNewLocationsCollectOne : "stories/{story_id}/locations/collect-one",
  storiesCommands : "stories/{story_id}/commands",
  storiesRecommendations : "stories/{story_id}/recommendations/{recommendation_id}",
  storiesComplete : "stories/{story_id}/complete",
} as const;

export const APP_TOKEN = '01HBQ13WEMF3PE3X29N09ZGYJA.AyBEMLmpD9trX6kSb5yKaW7tlWeb1ofWjmypVICXXXED6buFPMaULa8e0atWsj0RVLdjM6K0P2pKkppViDg5UvELVlzEiBiZCAho';
