import React from "react";

type ChildProps = {
  howToUse: string;
  handleClick: () => void;
};

// わく旅の使い方
const HowToUse: React.FC<ChildProps> = (props) => {

  const handleHowToUse = () => {
    props.handleClick();
  }

  return (
    <div className={`pageoverlay ${props.howToUse}`} id="howToUseOverlay">
      <div className="pageoverlay-header">
        <span></span>
        <p className="small">わく旅の使い方</p>
        <div id="howToUseCloseButton" onClick={handleHowToUse}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.4167 4.16669L4.16675 15.4167M4.16675 4.16669L15.4167 15.4167"
              stroke="#4A4847"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="pageoverlay-container">
        <div className="note-container">
          <svg
            width="72"
            height="72"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M14.0869 0L36 21.913V72L14.0869 50.087V0Z" fill="#F2DF36"/>
            <path d="M57.9131 0L36 21.913V72L57.9131 50.087V0Z" fill="#6ECF4C"/>
          </svg>
          <div>
            <p>【利用方法】</p>
            <div className="note-list">
              <div>
                <p>●</p>
                <div>
                  高速道路走行前にお車を停車した状態で、メニューボタンより「わく旅」を起動し、ご利用中はこの画面を一番上に表示したままにし、スリープ状態にしないようお願いいたします。
                </div>
              </div>
              <div>
                <p>●</p>
                <div>
                  安全のため、走行中の画面操作等はご遠慮ください。
                </div>
              </div>
              <div>
                <p>●</p>
                <div>
                  位置情報（GPS）は「常に許可」に設定し、位置情報を有効にしてください。
                </div>
              </div>
              <div>
                <p>●</p>
                <div>
                  「わく旅」の利用料は無料ですが、情報を受信するための通信料はお客さまのご負担となります。
                </div>
              </div>
              <div>
                <p>●</p>
                <div>
                  サービス提供時間は、7:00～17:00です。
                </div>
              </div>
              <div>
                <p>●</p>
                <div>
                  サービス提供時間外は、ボタンが押せない状態となります。
                </div>
              </div>
            </div>
          </div>
          <div>
            <p>【サービス提供路線】</p>
            <div className="note-list">
              <div>
                <p>●</p>
                <div>東名高速道路：東京IC~小牧IC</div>
              </div>
              <div>
                <p>●</p>
                <div>新東名高速道路：御殿場JCT~豊田東JCT</div>
              </div>
              <div>
                <p>●</p>
                <div>上記以外の高速道路では音声は流れません。</div>
              </div>
            </div>
          </div>
          <div>
            <p>【お問い合わせ】</p>
            <div className="note-list">
              <div>
                <p>●</p>
                <div>
                  「わく旅」の情報の内容、NEXCO中日本管内エリアの料金・交通情報などに関するお問合せ等につきましては、
                  <a href="https://highwaypost.c-nexco.co.jp/sys/inquiry/entry/agree">お客さまセンター（お問合せフォーム）</a>
                  までお願いいたします。
                </div>
              </div>
              <div>
                <p>●</p>
                <div>
                  情報は適宜見直しておりますが、お気づきの点がございましたらご連絡いただけますと幸いです。
                </div>
              </div>
              <div>
                <p>●</p>
                <div>お問い合わせに対する返信には数日いただく場合がございます。</div>
              </div>
            </div>
          </div>
          <div>
            <p>【道路の破損や落下物などの緊急事態を発見したとき】</p>
            <div className="note-list">
              <div>
                <p>●</p>
                <div>
                  道路緊急ダイヤル <a href={"tel:9910"}>＃9910</a>
                  ※道路緊急ダイヤルは緊急用です。それ以外のお問い合わせは各社のお客さまセンターか（公財）日本道路交通情報センターへお願いします。（公財）日本道路交通情報センター：<a href={"tel:050-3369-6666"}>050-3369-6666</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default HowToUse;
