import React, {useState, useEffect, PropsWithChildren, createContext} from 'react';

export interface IPosition {
  latitude: number | null;
  longitude: number | null;
  error: string | null;
}

export const LocationContext = createContext<IPosition | undefined>(undefined);

const LocationProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [position, setPosition] = useState<IPosition>({
    latitude: null,
    longitude: null,
    error: null
  });

  useEffect(() => {
    let geoId :number;

    const fetchGeoLocation = () => {
      const options : PositionOptions = {
        enableHighAccuracy: true,
      };
      geoId = window.navigator.geolocation.watchPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          setPosition({
            latitude,
            longitude,
            error: null
          });

          window.navigator.geolocation.clearWatch(geoId);
        },
        (error) => {
          console.error(error);
          // 位置情報権限エラーだけ画面上でエラー表示するために設定
          if (error.code === 1) {
            setPosition((pos) => ({
              ...pos,
              error: error.message
            }));
          }
          window.navigator.geolocation.clearWatch(geoId);
        }
      , options);

      setTimeout(fetchGeoLocation, 5000);
    };

    fetchGeoLocation();

    return () => {
      window.navigator.geolocation.clearWatch(geoId);
    };
  }, []);

  return (
    <LocationContext.Provider value={position}>
      {children}
    </LocationContext.Provider>
  );
};

export default LocationProvider;
