import React from "react";

type ChildProps = {
  terms: string;
  handleClick: () => void;
};

// 利用規約
const Term: React.FC<ChildProps> = (props) => {

  const handleTerm = () => {
    props.handleClick();
  }

  return (
    <div className={`pageoverlay ${props.terms}`} id="termsOfServiceOverlay">
      <div className="pageoverlay-header">
        <span></span>
        <p className="small">利用規約</p>
        <div id="termsOfServiceCloseButton" onClick={handleTerm}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.4167 4.16669L4.16675 15.4167M4.16675 4.16669L15.4167 15.4167"
              stroke="#4A4847"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="pageoverlay-container">
        <div className="note-container">
          <div>
            <p>
              ご利用の際は、わく旅の使い方をよくお読みいただき、ご同意の上、ご利用をお願いいたします。
            </p>
            <div className="note-list">
              <div>
                <p>●</p>
                <div>当アプリは、中日本高速道路株式会社が運営しています。</div>
              </div>
              <div>
                <p>●</p>
                <div>
                  お客さまは当アプリを本規約に承諾の上利用するものとします。お客さまが当アプリの利用を開始した場合は、本規約を承諾したものとみなされます。
                </div>
              </div>
              <div>
                <p>●</p>
                <div>
                  ご利用されるインターネット接続端末の機種、利用環境、GPS衛星の電波状況及び電波基地局の状況によっては、表示、動作及び機能などの制限、情報が取得できない場合や誤差が生じる場合があります。予めご了承願います。
                </div>
              </div>
              <div>
                <p>●</p>
                <div>
                  道路交通法により走行中のドライバーによる携帯電話の使用は禁止されています。
                </div>
              </div>
              <div>
                <p>●</p>
                <div>
                  当社は当アプリの情報提供について、最新の注意を払っておりますが、提供された情報の更新時期、及びその内容について一切保証をするものではありません。
                </div>
              </div>
              <div>
                <p>●</p>
                <div>
                  当社は理由の如何に関わらず、情報の変更及び当アプリ運営の中断または中止によって生じるいかなる損害についても責任を負うものではありません。なお、当アプリで提供されている情報等につきましては、予告なく変更、又は削除されることがありますので、あらかじめご了承願います。
                </div>
              </div>
              <div>
                <p>●</p>
                <div>
                  本サービスは、通信回線設備、システムの障害、メンテナンス、天変地異その他やむを得ない事由により、お客さまに事前に通知することなく一時的に遅延又は中断されることがあります。当社は、本サービスの遅延または中断等が発生したとしても、これに起因するお客さま又は他の第三者が被った損害について一切の責任を負いかねます。
                </div>
              </div>
              <div>
                <p>●</p>
                <div>
                  当アプリに収録されているコンテンツの著作権は、当社に帰属します。当アプリについてコンテンツの全部または一部を当社の許諾なく複製頒布、出版、放送、上演等に二次利用することはできません。://www.c-nexco.co.jp/privacy_policy/）
                </div>
              </div>
              <div>
                <p>●</p>
                <div>
                  当社は、当アプリの提供を通じて当社が取得するお客さまの個人情報について、弊社所定のプライバシーポリシーを遵守し、適切に管理するものとします。
                </div>
              </div>
              <div>
                <p>●</p>
                <div>
                  当社は、お客さまによる当アプリの利用によって取得する情報（GPS機能により取得される位置情報）について、当アプリもしくはこれに関連するサービス又は当社のその他の事業のために利用（統計資料の作成及び当該事業に必要な範囲おいて第三者に提供することを含みます）することがあります。
                </div>
              </div>
              <div>
                <p>●</p>
                <div>
                  情報を第三者へ提供する場合、適切な匿名化処置を施し、匿名化した情報を再識別しないものとします。また、これらの情報を第三者へ提供する場合は、提供先が再識別化することを契約で禁止します。当社のプライバシーポリシーの規定によらず、お客さまの個人情報を第三者に提供することはありません。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Term;
